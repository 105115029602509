import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "@kraftheinz/common";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Activity" } }, [_c("select-input", { key: "customerId", attrs: { "default-active-first-option": "", "label": "Key Account", "option-filter": _vm.filterCustomers, "place-holder": "Key Account", "reference": "promotion-planning.common.customers", "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["region", "keyAccountCode", "keyAccount"]);
  }, "span": 24 } }), _c("text-input", { key: "description", attrs: { "label": "Activity", "place-holder": "Activity", "max-length": 512, "span": 24 } }), _c("text-input", { key: "rate", attrs: { "label": "Rate", "max-length": 6, "place-holder": "Rate", "prefix": "%", "rules": "number|between:0,100|decimal:3,2", "span": 24 } }), _c("select-input", { key: "categoryId", attrs: { "label": "Category", "reference": "promotion-planning.common.primary-instore.category", "source": "id", "source-label": "description", "rules": "required", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateLocation",
  mixins: [PromoUtils],
  methods: {
    filterCustomers(customer) {
      return customer.id !== 0;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateLocation = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-location" }, [_c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.primary-instore.category", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.primary-instore", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/activity-list" } }, [_c("create-location")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateLocation
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
